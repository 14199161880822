.sizes {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 66px;
  @include contentPaddingInline;

  @include onTablet {
    gap: 30px;
  }

  @include onMobile {
    gap: 20px;
    padding-inline: 0;
  }

  &__title {
    @extend %bebas-font;
    font-size: 36px;
    line-height: 150%;
    letter-spacing: 5.58px;
    text-transform: uppercase;
    margin-block: 107px 0;

    @include onMobile {
      @include contentPaddingInline;
      margin-top: 312px;
    }
  }

  &__content {
    @include grid;

    @include onMobile {
      display: block;

      & > :not(.sizes__content-image) {
        @include contentPaddingInline;
      }
    }

    &-text {
      &-top {
        grid-row: 1;
        grid-column: 1/4;

        color: $gray-2-color;
        @extend %manrope-font;
        font-size: 16px;

        @include onTablet {
          grid-column: 1/-2;
          padding-bottom: 38px;
        }

        @include onMobile {
          padding-bottom: 30px;
        }
      }

      &-bottom {
        position: relative;
        grid-row: 1;
        grid-column: 1/7;

        padding-top: 350px;

        @extend %manrope-font;
        color: $gray-2-color;
        font-size: 16px;

        &::before {
          position: absolute;
          content: "*";
          left: 20px;
        }

        @include onTablet {
          grid-column: 1/-1;
          grid-row: 3;

          padding-top: 20px;
        }

        @include onMobile {
          padding-top: 15px;
        }
      }
    }

    &-types {
      grid-row: 1;
      grid-column: 4/7;

      &-content {
        position: relative;

        display: flex;
        flex-direction: column;

        &::before {
          position: absolute;
          content: "";
          left: -20px;
          width: 1px;
          height: 100%;
          background-color: #d8d8d8;
        }
      }

      &-title {
        margin-block: 0 10px;
        @extend %bebas-font;
        font-size: 18px;
        line-height: 100%;
        letter-spacing: 2.79px;

        &:not(:first-of-type) {
          margin-top: 40px;

          @include onTablet {
            margin-top: 50px;
          }

          @include onMobile {
            margin-top: 40px;
          }
        }
      }

      &-info {
        color: $gray-2-color;
        @extend %manrope-font;
        font-size: 16px;
      }

      @include onTablet {
        grid-row: 2;
        grid-column: 1/3;

        &-content::before {
          display: none;
        }
      }
    }

    &-image {
      grid-row: 1;
      grid-column: 7/-1;
      padding-left: 40px;

      &-content {
        max-width: 100%;
        min-width: 100%;
      }

      @include onTablet {
        grid-row: 2;
        grid-column: 3/-1;
      }

      @include onMobile {
        padding-left: 0;
        padding-bottom: 30px;

      }
    }
  }
}
