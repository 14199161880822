.header {
  position: relative;
  background: url(./../images/background-yellow.svg);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 72vw auto;
  min-height: 740px;
  @include widthBounds;

  @include onTablet {
    background-size: 85vw auto;
  }

  @include onMobile {
    background-image: none;
  }

  &__top {
    @include grid;
    @include contentPaddingInline;
    align-items: center;
    height: 70px;

    &-logo {
      grid-column: span 4;

      @include onMobile {
        grid-column: 1/3;
      }

      &-content {
        text-transform: uppercase;
        text-decoration: none;
        font-size: 36px;
        line-height: 70px;
        letter-spacing: 2.16px;
        transition: letter-spacing 0.3s;

        &:link,
        &:visited {
          color: $gray-1-color;
        }

        &:hover {
          letter-spacing: 3px;
        }

        &:active {
          letter-spacing: 2.16px;
        }
      }
    }

    &-icon {
      background-image: url(./../images/basket-icon.png);
      grid-column: -2;
      background-position: center;
      background-size: cover;
      cursor: pointer;
      width: 48px;
      height: 48px;
      justify-self: flex-end;
      @include hover(transform, scale(1.1));

      @include onTablet {
        background-image: url(./../images/menu-icon.svg);
        width: 32px;
        height: 32px;
        margin-right: 8px;
      }

      @include onMobile {
        grid-column: 3;
      }
    }
  }

  &__nav {
    &-container {
      grid-column: span 7;

      @include onTablet {
        display: none;
      }
    }

    &-list {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      gap: 60px;
    }

    &-item {
      cursor: pointer;
      position: relative;

      &::after {
        position: absolute;
        content: "";
        bottom: 10px;
        height: 1px;
        width: 100%;
        background-color: $gray-2-color;
        transition: transform 0.3s;
        transform: scaleX(0);
      }

      &:hover::after {
        transform: scaleX(1);
      }
    }

    &-link {
      display: block;
      text-decoration: none;
      @extend %manrope-font;
      font-size: 16px;
      line-height: 300%;
      font-weight: 400;

      &:link,
      &:visited {
        color: $gray-2-color;
      }
    }
  }

  &__bottom {
    @include contentPaddingInline;
    @include grid;
    margin-top: 70px;

    @include onMobile {
      padding-inline: 0;
    }

    @include onTablet {
      margin-top: 50px;
    }

    @include onMobile {
      display: block;
      margin-top: 0;
    }

    &-content {
      display: grid;
      grid-template-columns: repeat(5,1fr);
      grid-template-rows: auto;
      grid-row: 1;
      grid-column: 2/7;
      white-space: nowrap;

      @include onTablet {
        grid-template-columns: repeat(4,1fr);
        grid-column: 1/5;
      }

      @include onMobile {
        @include contentPaddingInline;
      }

      &-title {
        margin: 0;
        font-size: 64px;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: 9.92px;
        text-transform: uppercase;
        z-index: 3;
        -webkit-text-stroke-color: $turquoise-color;
        -webkit-text-stroke-width: 1px;
        color: transparent;
        grid-column: 1/5;

        @include hover(transform, skewX(-5deg));

        &--inner {
          color: $turquoise-color;
        }

        @include onMobile {
          margin-top: 25px;
          justify-self: center;
          font-size: 36px;
          line-height: normal;
          letter-spacing: 5.58px;
          text-transform: uppercase;
        }
      }

      &-buy-info {
        display: flex;
        flex-direction: column;
        gap: 5px;
        @extend %manrope-font;
        justify-self: right;
        grid-row: 2;
        grid-column: 4/-1;

        @include onTablet {
          gap: 1px;
          grid-column: 3;
        }

        @include onMobile {
          margin-top: 30px;
          grid-column: 1;
        }
      }

      &-price {
        color: $gray-1-color;
        font-size: 26px;
        font-weight: 700;
        line-height: normal;
      }

      &-article {
        color: $gray-2-color;
        font-size: 14px;
        font-weight: 400;
      }

      &-button {
        margin-top: 50px;
        grid-row: 3;
        grid-column: 3/6;

        @include onTablet {
          grid-column: 2/4;
        }

        @include onMobile {
          margin-top: 20px;
          grid-column: 1/-1;
        }
      }

      &-more {
        position: absolute;
        transform: rotate(90deg);
        transform-origin: bottom right;
        left: -50px;
        bottom: 10px;

        @include onTablet {
          left: -152px;
        }

        @include onMobile {
          transform-origin: bottom right;
          left: unset;
          right: 20px;
        }

        &-link {
          @extend %manrope-font;
          font-size: 12px;
          text-decoration: none;
          font-weight: 400;

          &:link,
          &:visited {
            color: $turquoise-color;
          }

          &-arrow {
            fill: $turquoise-color;
            transition: transform 0.3s;
          }
        }

        &:hover &-link-arrow {
          transform: translateX(10px);
        }
      }
    }

    &-image {
      grid-row: 1;
      grid-column: 8/-1;
      z-index: 2;

      @include onTablet {
        grid-column: 4/7;
      }

      &-content {
        display: block;
        width: 100%;
        max-width: 100%;
        transition: border-radius 0.3s, transform 0.3s;

        &:hover {
          border-radius: 25%;
          transform: scale(1.1);

          @include onMobile {
            border-radius: 0;
            transform: scale(1);
          }
        }
      }
    }
  }
}
