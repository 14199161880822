.for-whom {
  overflow: hidden;
  position: relative;
  @include contentPaddingInline;
  @include widthBounds;

  &__padding-text {
    &--top {
      right: 75px;
      top: 65px;

      @include onTablet {
        right: -55px;
        top: -200px;
      }

      @include onMobile {
        display: none !important;
      }
    }

    &--bottom {
      left: 70px;
      bottom: 60px;

      @include onTablet {
        display: none;
      }

      @include onMobile {
        left: unset;
        right: -60px;
        bottom: 65px;
      }
    }
  }

  &__content {
    @include grid;
    row-gap: 80px;
    align-items: start;

    @include onTablet {
      row-gap: 110px;
    }

    @include onMobile {
      row-gap: 70px;
    }
  }

  &__title {
    margin: 0;
    margin-bottom: 42px;
    color: $gray-1-color;
    font-size: 36px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 5.58px;
    text-transform: uppercase;

    @include onTablet {
      margin-bottom: 19px;
    }

    @include onMobile {
      margin-bottom: 25px;
    }
  }

  &__item {
    display: grid;
    column-gap: 30px;
    align-items: center;

    &--1 {
      grid-template-columns: repeat(12,1fr);
      grid-row: 1;
      grid-column: 1/-1;
    }

    &--2 {
      grid-template-columns: repeat(5,1fr);
      grid-row: 2;
      grid-column: 1/6;
      margin-top: 105px;

      @include onTablet {
        margin-top: 0;
        grid-column: 1/-1;
      }
    }

    &--3 {
      grid-template-columns: repeat(6,1fr);
      grid-row: 2;
      grid-column: 7/-1;

      @include onTablet {
        grid-row: 3;
        grid-column: 1/-1;
      }
    }

    @include onTablet {
      grid-template-columns: repeat(12,1fr);
      column-gap: 10px;
    }
  }

  &__image {
    &--1 {
      grid-column: 1/6;

      @include onTablet {
        grid-column: 1/6;
      }
    }

    &--2 {
      grid-column: 2/5;

      @include onTablet {
        grid-column: 8/-1;
      }
    }

    &--3 {
      grid-column: 2/-1;

      @include onTablet {
        grid-row: 1;
        grid-column: 1/6;
      }
    }

    &-content {
      width: 100%;
      display: block;
    }

    @include onMobile {
      display: none;
    }
  }

  &-info-container {
    &--1 {
      display: flex;
      flex-direction: column;
      align-self: center;
      grid-column: 7/-2;

      @include onTablet {
        grid-column: 6/-1;
      }
    }

    &--2 {
      grid-row: 2;
      grid-column: 2/6;

      @include onTablet {
        grid-row: 1;
        grid-column: 1/7;
      }
    }

    &--3 {
      grid-row: 2;
      grid-column: 2/-1;

      @include onTablet {
        grid-row: 1;
        grid-column: 6/-1;
      }
    }

    @include onMobile {
      grid-column: 1/-1;
    }
  }

  &__description {
    @extend %manrope-font;
    display: flex;
    gap: 30px;
    align-items: end;
    margin-bottom: 40px;

    @include onTablet {
      gap: 20px;
      margin-bottom: 25px;
    }

    &-star {
      color: #000;
      font-size: 24px;
      letter-spacing: 3.72px;
    }

    &-text {
      color: $gray-1-color;
      font-size: 16px;
    }

    &--1 {
      margin-left: 53px;

      @include onTablet {
        margin-left: 16px;
      }
    }

    &--2 {
      margin-top: 45px;
      margin-left: -45px;

      @include onTablet {
        margin-top: 0;
        margin-bottom: 28px;
        justify-content: flex-end;
      }
    }

    &--3 {
      margin-top: 45px;
      margin-left: -45px;

      @include onTablet {
        margin-left: 15px;
        margin-bottom: 17px;
      }
    }

    @include onMobile {
      display: none;
    }
  }

  &__article {
    display: flex;
    gap: 68px;

    @include onTablet {
      gap: 20px;
    }

    &--2 {
      flex-direction: column;
      gap: 10px;
    }

    &-number {
      display: flex;
      color: #000;
      font-size: 36px;
      line-height: 180%;

      &::before {
        content: "";
        background-color: $gray-1-color;
        width: 1px;
        height: 46px;
        margin-right: 5px;
        margin-left: 10px;
        transform: rotate(22deg);
      }
    }

    &-info {
      &-title {
        position: relative;
        color: #000;
        font-size: 36px;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 5.58px;
        text-transform: uppercase;
        margin: 0;
        margin-bottom: 10px;

        @include onMobile {
          margin-bottom: 20px;
        }

        &-slash {
          position: absolute;
          bottom: 5px;

          @include onMobile {
            display: none;
          }
        }
      }

      &-text {
        color: $gray-2-color;
        @extend %manrope-font;
        font-size: 16px;
      }
    }

    @include onMobile {
      flex-direction: column;
      gap: 0;
    }
  }
}
