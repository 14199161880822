@import "utils";

@import "./blocks/user-inputs";
@import "./blocks/header";
@import "./blocks/menu";
@import "./blocks/main";
@import "./blocks/for-whom";
@import "./blocks/features";
@import "./blocks/team";
@import "./blocks/materials";
@import "./blocks/sizes";
@import "./blocks/order";
@import "./blocks/questions";

html {
  scroll-behavior: smooth;
}

body {
  @extend %bebas-font;
  margin: 0;
  background-color: $grey-light-color;
  align-items: center;
  @include widthBounds;
}

p {
  margin: 0;
}

.padding-text {
  position: absolute;
  display: flex;
  align-items: center;
  color: #828282;
  @extend %bebas-font;
  font-size: 12px;
  letter-spacing: 2.64px;
  text-transform: uppercase;
  transform: rotate(90deg);

  &:before {
    content: "";
    width: 65px;
    height: 1px;
    background-color: #828282;
    margin-right: 10px;
  }
}

.animated {
  opacity: 0;
  transition: opacity 1s ease, transform 1s ease;

  &-left {
    transform: translateX(-30%);
  }

  &-right {
    transform: translateX(30%);
  }

  &-top {
    transform: translateY(-30%);
  }

  &-bottom {
    transform: translateY(30%);
  }

  &-rotate {
    transform: rotate(360deg);
  }
}

.visible {
  opacity: 1;
  transform: none;
}
