.main {
  margin: 0;
  padding-top: 150px;

  @include onTablet {
    padding-top: 60px;
  }

  @include onMobile {
    padding-top: 110px;
  }
}
