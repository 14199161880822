.team {
  position: relative;
  @include widthBounds;
  overflow: hidden;
  min-height: 940px;
  background-image: url(./../images/background-yellow.svg);
  background-repeat: no-repeat;
  background-size: 100vw;
  background-position: -67vw 180px;
  display: flex;
  flex-direction: column;
  margin-top: 174px;

  @include onTablet {
    min-height: 640px;
    margin-top: 132px;
  }

  @include onMobile {
    margin-top: 110px;
    min-height: unset;
    background-size: 220vw;
    background-position: left bottom;
  }

  &__style-word {
    position: absolute;

    font-size: 288px;
    letter-spacing: 45px;
    z-index: 1;

    -webkit-text-stroke-color: $turquoise-color;
    -webkit-text-stroke-width: 1px;
    color: transparent;

    &--martin {
      left: 74px;
      top: 80px;

      @include onTablet {
        left: -40px;
        top: 60px;
        font-size: 230px;
      }
    }

    &--andy {
      position: relative;
      line-height: 73%;
      align-self: flex-end;
      right: -30px;
      bottom: -20px;

      @include onTablet {
        position: absolute;
        font-size: 220px;
        bottom: -10px;
      }
    }

    @include onMobile {
      display: none;
    }
  }

  &__title {
    @include contentPaddingInline;
    margin: 0;

    color: $gray-1-color;
    font-size: 58px;
    font-weight: 400;
    letter-spacing: 9px;
  }

  &__content {
    @include contentPaddingInline;
    @include widthBounds;
    @include grid;
    margin-top: 145px;

    @include onMobile {
      padding-inline: 0;
      margin-top: 25px;
    }
  }

  &__text {
    margin-right: 20px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 30px;
    grid-column: 1/5;

    @include onTablet {
      grid-column: 1/4;
      margin-top: 55px;
    }

    @include onMobile {
      @include contentPaddingInline;
      margin-right: 0;
      margin-top: 30px;
      margin-bottom: 75px;
      grid-row: 2;
      grid-column: 1/-1;
    }

    &-paragraph {
      color: $gray-2-color;
      @extend %manrope-font;
      font-size: 16px;
      line-height: 150%;
    }
  }

  &__image {
    grid-column: 5/-2;

    &-content {
      position: relative;
      display: block;
      max-width: 100%;
    }

    @include onTablet {
      grid-column: 4/-1;
    }

    @include onMobile {
      grid-row: 1;
      grid-column: 1/-1;
    }
  }
}
